<template>
  <div class="auth-view">
    <main>
      <img
        class="hidden md:block"
        src="@/assets/points.png"
        id="points"
        alt="Points background"
      />
      <section style="height: 95vh">
        <LoginForm :errorMessage="errorMessage" @loginEvent="handleLogin" :userEmail="email" />
      </section>
    </main>
    <ContactFooter />
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { createClient } from "@/services/stripe/stripe.js";
import { getNumberDependents } from "@/services/user/user.js";
import LoginForm from "@/components/auth/LoginForm.vue";
import ContactFooter from "@/components/general/ContactFooter.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();

const setUserIsLogged = (value) => store.commit("user/setUserIsLogged", value);

const login = async (email, password) =>
  store.dispatch("user/login", { email, password });

const user = computed(() => store.getters["user/getUserData"]);

const errorMessage = ref(null);
const email = ref(null)

onMounted(() => {
  email.value = route.query.email;
});

async function handleLogin(email, password) {
  const loginResponse = await login(email, password);

  await validateLoginResponse(loginResponse);
}

async function validateLoginResponse(loginResponse) {
  if (!loginResponse.isLogged) {
    errorMessage.value = "Sorry, your password was incorrect.";
    return;
  }

  if (!user.value["is_active"]) {
    sessionStorage.setItem("parentEmail", user.value["email"]);
    await getOrCreateClient(user.value["email"]);
    return;
  }

  if (
    !user.value["mfaActivated"] &&
    user.value["role"] === "Mastermind_admin"
  ) {
    router.push({ name: "TOTP Register" });
    return;
  }

  if (user.value["role"] === "Mastermind_admin") {
    router.push({ name: "TOTP Login" });
    return;
  }

  const dependents = await verifyNumberDependents();
  if (dependents > 0) {
    sessionStorage.setItem("parentEmail", loginResponse.email);
    return router.push({
      name: "Register players",
      query: { n: dependents },
    });
  }

  setUserIsLogged(true);
  return router.push({
    name: "Home",
    query: { messages: "true" },
  });
}

async function verifyNumberDependents() {
  const responseNumberDependents = await getNumberDependents(user.value["id"]);
  return responseNumberDependents.data.dependents_to_register;
}

async function getOrCreateClient(email) {
  const response = await createClient(email, user.value["userName"]);

  if (response) {
    const location = user.value["location"];
    if (response.success) {
      router.push({
        name: "Check Out",
        query: {
          customerId: response.data.customer.id,
          s: location ? false : true,
          l: location,
        },
      });
      return;
    }
  }
}
</script>
<style scoped>
#login-text {
  margin-bottom: 7%;
}

.auth-view {
  background: url("../../assets/background-login.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#points {
  position: absolute;
  top: 2%;
  left: 50%;
  width: 17%;
  height: 34%;
  text-align: center;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
</style>
