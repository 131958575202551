<template>
  <Card class="md:w-1/2 h-full bg-white flex items-center overflow-auto">
    <template v-slot:card-header>
      <Logo
        :path="logo"
        className="logo flex justify-center"
        width="100"
      ></Logo>
    </template>
    <template v-slot:card-body>
      <div class="mt-8">
        <h2 class="text-2xl font-semibold">LOGIN</h2>
        <p class="italic">Login to manage your account</p>
      </div>
      <form @submit.prevent="submitLogin" class="mt-8">
        <div class="form__div">
          <input
            class="form__input"
            type="email"
            id="email"
            placeholder=" "
            v-model="email"
            required
          />
          <label for="email" class="form__label">Email</label>
        </div>
        <div class="form__div">
          <input
            class="form__input"
            type="password"
            placeholder=" "
            id="password"
            v-model="password"
            autocomplete="on"
            minlength="8"
            required
          />
          <label for="password" class="form__label">Password</label>
        </div>
        <div class="mt-6 md:mt-2 text-right underline">
          <router-link :to="{ name: 'Forgot Password' }" class="text-color">
            Forgot Password?
          </router-link>
        </div>
        <div v-if="props.errorMessage" class="mt-10 text-center text-red-600">
          <span
            ><em class="fa-solid fa-circle-exclamation mr-1"></em>
            {{ props.errorMessage }}</span
          >
        </div>
        <button
          class="w-full text-darkBlue mt-8 p-3 border-0 bg-mindaro font-semibold"
          type="submit"
        >
          Login
        </button>
      </form>
    </template>
    <template v-slot:card-footer>
      <div class="text-center md:mt-5">
        Don't have an account? |
        <router-link class="text-color underline" :to="{ name: 'SignUp' }">
          Register Now
        </router-link>
      </div>
    </template>
  </Card>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from "vue";
import Logo from "@/components/auth/Logo";
import Card from "@/components/auth/Card";

const emit = defineEmits(["loginEvent"]);
const props = defineProps({
  errorMessage: {
    type: String,
  },
  userEmail: {
    type: String,
    default: "",
  },
});

const email = ref(null);
const password = ref(null);
const logo = ref(require("@/assets/icons/mm-icon.svg"));

watch(() => props.userEmail, (newValue) => {
  email.value = newValue || null;
}, { immediate: true });

function submitLogin() {
  if (email.value && password.value)
    return emit("loginEvent", email.value, password.value);
}
</script>
